import backgroundImage from '../../icons/backgrounds/background-plasma.webp'; //default
import blue_bush from '../../icons/backgrounds/blue_bush.webp';
import green_leaves from '../../icons/backgrounds/more-leaves-on-green.png';
import gray_leaves from '../../icons/backgrounds/Leaves-Pattern.webp';
import sun_pattern from '../../icons/backgrounds/sun-pattern.webp';
import blue_snow from '../../icons/backgrounds/blue-snow.webp';
import cork_board from '../../icons/backgrounds/cork-board.webp';
import morocco_blue from '../../icons/backgrounds/morocco-blue.png';
import pipes_pattern from '../../icons/backgrounds/pipes-pattern.webp';
import tiny_squares from '../../icons/backgrounds/tiny-squares.webp';
import memphis_colorful from '../../icons/backgrounds/memphis-colorful.png';
import fresh_snow from '../../icons/backgrounds/fresh_snow.png';


const PlasmaBackgrounds = {
    default: backgroundImage,
    blue_bush: blue_bush,
    green_leaves: green_leaves,
    gray_leaves: gray_leaves,
    sun_pattern: sun_pattern,
    cork_board: cork_board,
    morocco_blue: morocco_blue,
    pipes_pattern: pipes_pattern,
    blue_snow: blue_snow,
    tiny_squares: tiny_squares,
    memphis_colorful: memphis_colorful,
    fresh_snow: fresh_snow,
    jerusalem: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/%D7%9E%D7%92%D7%93%D7%9C_%D7%93%D7%95%D7%93.jpg/1920px-%D7%9E%D7%92%D7%93%D7%9C_%D7%93%D7%95%D7%93.jpg',
    mearat_machpela: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a3/Patriarch_tomb.JPG/1200px-Patriarch_tomb.JPG',
    tirat_zvi: 'https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/attachments%2FPHOTO-2022-09-24-23-45-36.jpg?alt=media&token=c04e734f-446c-4eaf-999e-d18ccdf6c922',
    tirat_zvi2: 'https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/attachments%2FScreenshot%202024-12-13%20at%2015.20.47.png?alt=media&token=081f18a8-a7bf-45d5-9ffb-c365eea5ae83',
    jerusalem_1: 'https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/attachments%2FWhatsApp%20Image%202025-04-10%20at%2018.32.46.jpeg?alt=media&token=2cf6cc15-6495-48d2-890c-779d91f28a2f',

}

export default PlasmaBackgrounds;